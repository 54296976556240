body {
  background: rgb(2,24,89);
  background: linear-gradient(0deg, rgba(2,24,89,1) 0%, rgba(4,66,191,1) 100%);
  margin: 0;
  margin-bottom: 0;
  overflow: hidden;
}

.titles {
  color: rgb(255, 255, 255);
  font-size: 3rem;
  margin-left: 10%;
  margin-bottom: 5%;
}

.footer {
  background-color: rgb(255, 0, 0);
  color: white;
}

.hello {
  color: white;
}

.hello:hover {
  color: #f25c05;
}

.big-box {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-box {
  padding: 20%;
  background-color: rgba(255, 255, 255, 0.1);
  color: azure;
  font-size: 30px;
  box-shadow: 0 0 100px rgba(255, 255, 255, 0.1);
}

.first-line {
  color: white;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.second-line {
  color: white;
  font-size: 100rem;
}

.bio{
  color: rgb(255, 240, 240);
  padding: 5%;
  font-size: 1rem;
  border-color: brown;
  border: 10px;
}

.header {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 10px;
  right: 10px;
  width: 100%;
}

.header-content {
  display: flex;
  gap: 15px;
}

.footer {
  background: none;
  margin-top: 200px; /* Add margin to separate cards from footer */
  position: absolute;
  bottom: 0; /* Ensure the footer sticks to the bottom */
  left: 0;
  right: 0;
}

.equal-height-cards {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 200px; /* Add margin at the bottom of the card container */
}

.column {
  display: flex;
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%; /* Fill the available height */
}

.card-content {
  flex: 1; /* Allow content to expand to fill available space */
}

.project-image {
  width: 100%; /* Make the image width fill the container */
  height: 200px; /* Set a fixed height for all images */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
}


body {
  background-color: #444442;
  padding-top: 85px;
}

h1 {
  font-family: 'Poppins', sans-serif, 'arial';
  font-weight: 600;
  font-size: 72px;
  color: white;
  text-align: center;
}

h4 {
  font-family: 'Roboto', sans-serif, 'arial';
  font-weight: 400;
  font-size: 20px;
  color: #9b9b9b;
  line-height: 1.5;
}

/* ///// inputs /////*/

input:focus ~ label, textarea:focus ~ label, input:valid ~ label, textarea:valid ~ label {
  font-size: 0.75em;
  color: #999;
  top: -5px;
  -webkit-transition: all 0.225s ease;
  transition: all 0.225s ease;
}

.styled-input {
  float: left;
  width: 293px;
  margin: 1rem 0;
  position: relative;
  border-radius: 4px;
}

@media only screen and (max-width: 768px){
  .styled-input {
      width:100%;
  }
}

.styled-input label {
  color: #999;
  padding: 1.3rem 30px 1rem 30px;
  position: absolute;
  top: 10px;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

.styled-input.wide { 
  width: 650px;
  max-width: 100%;
}

input,
textarea {
  padding: 30px;
  border: 0;
  width: 100%;
  font-size: 1rem;
  background-color: #2d2d2d;
  color: white;
  border-radius: 4px;
}

input:focus,
textarea:focus { outline: 0; }

input:focus ~ span,
textarea:focus ~ span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

textarea {
  width: 100%;
  min-height: 15em;
}

.input-container {
  width: 650px;
  max-width: 100%;
  margin: 20px auto 25px auto;
}

.submit-btn {
  padding: 7px 35px;
  border-radius: 60px;
  display: inline-block;
  background-color: #4b8cfb;
  color: white;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.06),
            0 2px 10px 0 rgba(0,0,0,0.07);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.submit-btn:hover {
  transform: translateY(1px);
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.10),
            0 1px 1px 0 rgba(0,0,0,0.09);
}

@media (max-width: 768px) {
  .submit-btn {
      width:100%;
      float: none;
      text-align:center;
  }
}

input[type=checkbox] + label {
color: #ccc;
font-style: italic;
} 

input[type=checkbox]:checked + label {
color: #f00;
font-style: normal;
}